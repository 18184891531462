<template>
    <div>
        <div class="flex-page-content flex-container">
            <div class="flex-body body-scroll">
                <div class="container-fluid">

                    <div class="container-centered-s py-4">

                        <h5>{{ onboardingApplication.company.name }}</h5>
                        <h3 v-if="!activeForm && !activeHRFormDoc && !i9Mode && !activeUniform && !activeCustomForm">Required Documents</h3>

                        <div v-show="!activeForm && !activeHRFormDoc && !i9Mode && !activeUniform && !activeCustomForm">
                            <p class="mt-4">Complete the {{ numDocsToFill }} {{ numDocsToFill > 1 ? "documents" : "document" }} below.</p>

                            <div class="card doc-item mb-2" v-for="form in forms" :key="form.id">
                                <div class="card-body">
                                    <div class="todo-item" :class="{'todo-item-completed': form.filled_on}">
                                        <div class="todo-item-content">
                                            <h6 class="doc-title mb-2">{{ form.yaml|yamlTitle }}</h6>

                                            <div class="doc-action-primary mb-1">

                                                <a href class="btn btn-outline-primary" target="_blank" @click.prevent="i9Mode = true" v-if="!form.filled_on && form.form_designation == 'i9'">
                                                    <i class="far fa-fw fa-pencil mr-1"></i>Fill Out Document
                                                </a>

                                                <a href class="btn btn-outline-primary" target="_blank" @click.prevent="setActiveForm(form)" v-if="!form.filled_on && form.form_designation != 'i9'">
                                                    <i class="far fa-fw fa-pencil mr-1"></i>Fill Out Document
                                                </a>

                                                <a href v-if="form.filled_on" class="btn btn-outline-success" @click.prevent="previewPDFForm(form)">
                                                    <i class="far fa-fw fa-check mr-1"></i>View Signed Document
                                                </a>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <template v-for="doc in sortedDocsToComplete">

                            <div class="card doc-item mb-2" v-if="doc.hr_form_document_id">
                                <div class="card-body">
                                    <div class="todo-item" :class="{'todo-item-completed': doc.filled_on}">
                                        <div class="todo-item-content">
                                            <h6 class="doc-title mb-2">{{ doc.hr_form_document.name }}</h6>

                                            <div class="doc-action-primary mb-1">

                                                <a v-if="!doc.filled_on" href class="btn btn-outline-primary" @click.prevent="setActiveHRFormDoc(doc)">
                                                    <i class="far fa-fw fa-external-link mr-1"></i>Fill Out Document
                                                </a>

                                                <a href v-if="doc.filled_on" class="btn btn-outline-success" @click.prevent="previewHRFormDoc(doc)">
                                                    <i class="far fa-fw fa-check mr-1"></i>View Signed Document
                                                </a>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="card doc-item mb-2" v-if="doc.hr_document_id">
                                <div class="card-body">
                                    <div class="todo-item" :class="{'todo-item-completed': doc.signed_on}">
                                        <div class="todo-item-content">
                                            <h6 class="doc-title mb-2">{{ doc.hr_document.name }}</h6>

                                            <div class="doc-action-primary mb-1">

                                                <a v-if="!doc.signed_on" href class="btn btn-outline-primary" @click.prevent="showHRDocument(doc)">
                                                    <i class="far fa-fw fa-external-link mr-1"></i>Sign Document
                                                </a>

                                                <a href v-if="doc.signed_on" class="btn btn-outline-success" @click.prevent="showHRDocument(doc)">
                                                    <i class="far fa-fw fa-check mr-1"></i>View Signed Document
                                                </a>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="card doc-item mb-2" v-if="doc.uniform_id">
                                <div class="card-body">
                                    <div class="todo-item" :class="{'todo-item-completed': doc.filled_on}">
                                        <div class="todo-item-content">
                                            <h6 class="doc-title mb-2">{{ doc.uniform.name }}</h6>

                                            <div class="doc-action-primary mb-1">

                                                <a v-if="!doc.filled_on" href class="btn btn-outline-primary" @click.prevent="setActiveUniform(doc)">
                                                    <i class="far fa-fw fa-external-link mr-1"></i>Fill Out Document
                                                </a>

                                                <a href v-if="doc.filled_on" class="btn btn-outline-success" @click.prevent="setActiveUniform(doc)">
                                                    <i class="far fa-fw fa-check mr-1"></i>View Filled Document
                                                </a>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="card doc-item mb-2" v-if="doc.hr_custom_form_id">
                                <div class="card-body">
                                    <div class="todo-item" :class="{'todo-item-completed': doc.filled_on}">
                                        <div class="todo-item-content">
                                            <h6 class="doc-title mb-2">{{ doc.hr_custom_form.name }}</h6>

                                            <div class="doc-action-primary mb-1">

                                                <a v-if="!doc.filled_on" href class="btn btn-outline-primary" @click.prevent="setActiveCustomForm(doc)">
                                                    <i class="far fa-fw fa-external-link mr-1"></i>Fill Out Document
                                                </a>

                                                <a href v-if="doc.filled_on" class="btn btn-outline-success" @click.prevent="setActiveCustomForm(doc)">
                                                    <i class="far fa-fw fa-check mr-1"></i>View Filled Document
                                                </a>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            </template>

                            <form @submit.prevent="onSubmit">
                                <form-errors :errors="formErrors"/>

                                <div class="row mb-2 d-flex align-items-center">
                                    <div class="col">
                                        <router-link v-if="previousPage" :to="previousPage">Back</router-link>
                                    </div>

                                    <div class="col text-right">
                                        <button class="btn btn-primary">Continue</button>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <onboarding-i9-form
                            v-if="i9Mode"
                            :onboardingApplication="onboardingApplication"
                            :known-data="knownData"
                            @close="i9Mode = false"
                            @updated="$emit('updated')"
                        />

                        <div v-if="activeForm" class="mb-4">
                            <survey
                                :yaml="activeForm.yaml"
                                :knowndata="knownData"
                                @error="onSurveyError"
                                @submit="onPDFFormSubmit"
                                ref="activeFormSurvey"
                                :hidesubmit="true"
                                :pdf-url="activeForm.instructions_pdf_file_url || activeForm.pdf_file_url"
                            />

                            <form-errors :errors="formErrors" />

                            <button-row inline>
                                <a href @click.prevent="setActiveForm(null)">Back</a>
                                <button class="btn btn-primary" @click.prevent="onPDFFormSubmit">Continue</button>
                            </button-row>
                        </div>

                        <div v-if="activeHRFormDoc" class="mb-4">
                            <survey
                                :yaml="activeHRFormDoc.yaml"
                                :knowndata="knownData"
                                @error="onSurveyError"
                                @submit="onHRFormDocSubmit"
                                ref="activeHRFormDocSurvey"
                                :hidesubmit="true"
                                :pdf-url="activeHRFormDoc.instructions_pdf_file_url || activeHRFormDoc.pdf_file_url"
                            />

                            <form-errors :errors="formErrors" />

                            <button-row inline>
                                <a href @click.prevent="setActiveHRFormDoc(null)">Back</a>
                                <button class="btn btn-primary" @click.prevent="onHRFormDocSubmit">Continue</button>
                            </button-row>
                        </div>

                        <div v-if="activeUniform" class="mb-4">
                            <uniform-survey
                                @submit="onUniformSubmit"
                                :uniform="activeUniform"
                                ref="activeUniformSurvey"
                                :hidesubmit="true"
                            />

                            <form-errors :errors="formErrors" />

                            <button-row inline>
                                <a href @click.prevent="setActiveUniform(null)">Back</a>
                                <button class="btn btn-primary" @click.prevent="onUniformSubmit">Save</button>
                            </button-row>
                        </div>

                        <div v-if="activeCustomForm" class="mb-4">
                            <custom-form-survey
                                :custom-form="activeCustomForm"
                                ref="activeCustomFormSurvey"
                            />

                            <form-errors :errors="formErrors" />

                            <button-row inline>
                                <a href @click.prevent="setActiveCustomForm(null)">Back</a>
                                <button class="btn btn-primary" @click.prevent="onCustomFormSubmit">Save</button>
                            </button-row>
                        </div>

                    </div>

                </div>
            </div>
        </div>

        <forms-pdf-modal
            v-if="currentPDF"
            :onboarding-application="onboardingApplication"
            :hr-document="currentPDF.doc"
            :hr-form-document="currentPDF.formDoc"
            :pdf="currentPDF"
            :form="currentPDF.form"
            :form-data="currentPDF.formData"
            :known-data="knownData"
            :key="currentPDF.url.url"
            @close="currentPDF = null; activeForm = null; activeHRFormDoc = null"
            @updated="$emit('updated')"
        />


    </div>
</template>


<script>
import FormMixin from '@/mixins/Form'
import Survey from '@/components/PDFForm/Survey'
import WizardMixin from './WizardMixin'
import PdfModal from '@/components/PdfModal'
import ButtonRow from '@/components/ButtonRow'
import moment from 'moment'
import YAML from 'js-yaml'
import OnboardingI9Form from './OnboardingI9Form'
import FormsPdfModal from './FormsPDFModal'
import UniformSurvey from '@/components/UniformSurvey'
import CustomFormSurvey from '@/components/CustomFormSurvey'

export default {
    mixins: [FormMixin, WizardMixin, ],
    components: {Survey, PdfModal, ButtonRow, OnboardingI9Form, FormsPdfModal, UniformSurvey, CustomFormSurvey},
    props: ['onboardingApplication'],
    mounted() {
        this.$store.dispatch('GET_USER')
    },
    computed: {
        API_BASE() {
            return this.$api.API_BASE
        },
        forms() {
            const forms = this.onboardingApplication.forms
            forms.sort((a, b) => {
                if (a.priority_order == b.priority_order) {
                    return a.id > b.id ? 1 : -1
                }
                return (a.priority_order > b.priority_order) ? 1 : -1
            })
            return forms
        },
        numDocsToFill() {
            return this.onboardingApplication.forms.length + this.onboardingApplication.hr_documents.length + this.onboardingApplication.hr_form_documents.length + this.onboardingApplication.onboarding_application_uniforms.length + this.onboardingApplication.onboarding_application_hr_custom_forms.length
        },
        knownData() {
            const app = this.onboardingApplication
            return {
                'system': {
                    'date': moment().format('MM/DD/YYYY'),
                    'datetime': moment().format('MM/DD/YYYY HH:mm:ss Z'),
                },
                'employee': {
                    'first_name': app.first_name,
                    'middle_initial': app.middle_initial,
                    'last_name': app.last_name,
                    'full_name': app.full_name,
                    'email': app.email,
                    'suffix': app.suffix,
                    'address_line_1': app.address_1,
                    'address_line_2': app.address_2,
                    'city': app.city,
                    'city_normalized': app.city_normalized,
                    'state': app.state,
                    'state_long': app.state_long,
                    'postcode': app.postal_code,
                    'phone_number': app.phone_number,
                    'dob': moment(app.dob).format('MM/DD/YYYY'),
                    'date_hired': moment(app.date_hired).format('MM/DD/YYYY'),
                    'school_district_id': app.school_district_id,
                    'school_district_name': app.school_district_name,
                },
                'employer': {
                    'name': app.company.name,
                    'fein': app.company.EIN,
                    'address_line_1': app.company.address_1,
                    'address_line_2': app.company.address_2,
                    'city': app.company.city,
                    'state': app.company.state,
                    'state_long': app.company.state_long,
                    'postcode': app.company.postal_code,
                    'phone_number': app.company.phone_number,

                    'default_job_city': app.jobs[0].position.work_location.city_normalized,
                    'default_job_state': app.jobs[0].position.work_location.state,
                },
            }
        }
    },
    data() {
        return {
            formData: this.makeFormData(),
            activeForm: null,
            activeHRFormDoc: null,
            activeUniform: null,
            activeCustomForm: null,
            i9Mode: false,
            currentPDF: null,
            sortedDocsToComplete: this.makeSortedDocsToComplete()
        }
    },
    methods: {
        makeFormData() {
            return {
                action: 'complete-forms',
            }
        },
        makeSortedDocsToComplete() {
            // NB: does not include PDFForms (W4s, etc) which are displayed separately before any other docs
            const hrDocs = this.onboardingApplication.hr_documents
            hrDocs.forEach(d => d.name = d.hr_document.name)
            const uniforms = this.onboardingApplication.onboarding_application_uniforms
            uniforms.forEach(d => d.name = d.uniform.name)
            const hrCustomForms = this.onboardingApplication.onboarding_application_hr_custom_forms
            hrCustomForms.forEach(d => d.name = d.hr_custom_form.name)
            const hrFormDocs = this.onboardingApplication.hr_form_documents
            hrFormDocs.forEach(d => d.name = d.hr_form_document.name)
            const sortedDocsToComplete = hrDocs.concat(uniforms, hrCustomForms, hrFormDocs)
            sortedDocsToComplete.sort((a, b) => a.name > b.name ? 1 : -1)
            return sortedDocsToComplete
        },
        validate() {
            const basicFormValidation = FormMixin.methods.validate.call(this)

            if (this.forms.filter((f) => !f.filled_on).length) {
                this.formErrors.push("Complete all documents to continue.")
                return false
            }

            if (this.onboardingApplication.hr_documents.filter(d => !d.signed_on).length) {
                this.formErrors.push("Complete all documents to continue.")
                return false
            }

            if (this.onboardingApplication.hr_form_documents.filter(d => !d.filled_on).length) {
                this.formErrors.push("Complete all documents to continue.")
                return false
            }

            if (this.onboardingApplication.onboarding_application_uniforms.filter(d => !d.filled_on).length) {
                this.formErrors.push("Complete all documents to continue.")
                return false
            }

            if (this.onboardingApplication.onboarding_application_hr_custom_forms.filter(d => !d.filled_on).length) {
                this.formErrors.push("Complete all documents to continue.")
                return false
            }

            return basicFormValidation
        },
        onSubmit() {
            this.formErrors = []

            if (!this.validate()) {
                return
            }

            this.$store.dispatch('START_LOADING')
            this.$api.patch(`/me/onboarding/${this.onboardingApplication.id}/onboarding-application`, this.formData).then((resp) => {
                this.$emit('updated', resp)
                this.$emit('next')
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                this.errors = errors
                this.formErrors = errors.__all__
                this.onError()
                this.$store.dispatch('STOP_LOADING')
            })
        },
        close() {
            this.activeForm = null
            this.activeHRFormDoc = null
        },
        setActiveForm(form) {
            this.activeForm = null
            this.formErrors = []
            this.$nextTick(() => {
                this.activeForm = form
            })
        },
        setActiveHRFormDoc(formDoc) {
            this.activeHRFormDoc = null
            this.formErrors = []
            this.$nextTick(() => {
                this.activeHRFormDoc = formDoc
            })
        },
        onSurveyError(errors) {
            this.formErrors = []
            if (errors.message) {
                this.formErrors = errors.message
            }
            else if (errors.__all__) {
                this.formErrors = errors.__all__
            }
            else {
                this.formErrors = [errors]
            }
            this.$bus.showError(this.formErrors)
        },
        onPDFFormSubmit() {
            let data
            try {
                data = this.$refs.activeFormSurvey.getFormValues()
                delete data['signature']
                data.__yaml_form_id__ = this.activeForm.yaml_form_id
                data.__form_designation__ = this.activeForm.form_designation
                data.__onboarding_pdf_form_id__ = this.activeForm.id
            }
            catch (ex) {
                this.onSurveyError(ex)
                return
            }

            this.$store.dispatch('START_LOADING')
            this.$api.post(`/me/onboarding/${this.onboardingApplication.id}/onboarding-application/forms/${this.activeForm.id}`, {'form_data': data}).then(() => {
                this.$store.dispatch('STOP_LOADING')
                this.previewPDFForm(this.activeForm, data)
            }).catch((errors) => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__)
            })
        },
        onHRFormDocSubmit() {
            let data
            try {
                data = this.$refs.activeHRFormDocSurvey.getFormValues()
                delete data['signature']
                data.__onboarding_hr_form_doc_id__ = this.activeHRFormDoc.id
            }
            catch (ex) {
                this.onSurveyError(ex)
                return
            }

            this.$store.dispatch('START_LOADING')
            this.$api.post(`/me/onboarding/${this.onboardingApplication.id}/onboarding-application/hr-form-documents/${this.activeHRFormDoc.id}`, {'form_data': data}).then(() => {
                this.$store.dispatch('STOP_LOADING')
                this.previewHRFormDoc(this.activeHRFormDoc, data)
            }).catch((errors) => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__)
            })
        },
        onUniformSubmit() {
            if (!this.$refs.activeUniformSurvey.validate()) {
                return
            }
            let data = this.$refs.activeUniformSurvey.getFormData()
            this.$store.dispatch('START_LOADING')
            this.$api.post(`/me/onboarding/${this.onboardingApplication.id}/onboarding-application/uniforms/${this.activeUniform.id}`, data).then((resp) => {
                this.$store.dispatch('STOP_LOADING')
                let uniformIdxToUpdate = this.onboardingApplication.onboarding_application_uniforms.findIndex(u => u.id == resp.id)
                this.onboardingApplication.onboarding_application_uniforms[uniformIdxToUpdate] = resp
                this.sortedDocsToComplete = this.makeSortedDocsToComplete()
                this.setActiveUniform(null)
            }).catch((errors) => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__)
            })
        },
        onCustomFormSubmit() {
            if (!this.$refs.activeCustomFormSurvey.validate()) {
                return
            }
            let data = this.$refs.activeCustomFormSurvey.getFormData()
            this.$store.dispatch('START_LOADING')
            this.$api.post(`/me/onboarding/${this.onboardingApplication.id}/onboarding-application/hr-custom-forms/${this.activeCustomForm.id}`, data).then((resp) => {
                this.$store.dispatch('STOP_LOADING')
                let customFormIdxToUpdate = this.onboardingApplication.onboarding_application_hr_custom_forms.findIndex(u => u.id == resp.id)
                this.onboardingApplication.onboarding_application_hr_custom_forms[customFormIdxToUpdate] = resp
                this.sortedDocsToComplete = this.makeSortedDocsToComplete()
                this.setActiveCustomForm(null)
            }).catch((errors) => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__)
            })
        },
        showHRDocument(doc) {
            if (!doc) {
                this.currentPDF = null
                return
            }

            let url = ''
            if (doc.signed_on) {
                url = `${this.API_BASE}/me/onboarding/${this.onboardingApplication.id}/onboarding-application/hr-documents/${doc.id}/signed`
            }
            else {
                url = `${this.API_BASE}/me/onboarding/${this.onboardingApplication.id}/onboarding-application/hr-documents/${doc.id}/file`
            }

            this.currentPDF = {
                title: doc.hr_document.name,
                url: {
                    url: url,
                    withCredentials: true,
                },
                doc: doc,
            }
        },
        setActiveUniform(uniform) {
            this.activeUniform = uniform
        },
        setActiveCustomForm(customForm) {
            this.activeCustomForm = customForm
        },
        previewHRFormDoc(formDoc, formData) {
            this.currentPDF = {
                title: this.parseYAML(formDoc.yaml).title,
                url: {
                    url: `${this.API_BASE}/me/onboarding/${this.onboardingApplication.id}/onboarding-application/hr-form-documents/${formDoc.id}/pdf`,
                    withCredentials: true,
                },
                'formDoc': formDoc,
                'formData': formData,
            }
        },
        previewPDFForm(form, formData) {
            this.currentPDF = {
                title: this.parseYAML(form.yaml).title,
                url: {
                    url: `${this.API_BASE}/me/onboarding/${this.onboardingApplication.id}/onboarding-application/forms/${form.id}/pdf`,
                    withCredentials: true,
                },
                'form': form,
                'formData': formData,
            }
        },
        parseYAML(yaml) {
            return YAML.safeLoad(yaml)
        },
    },
    filters: {
        yamlTitle(yaml) {
            return YAML.safeLoad(yaml).title
        },
    },
}
</script>
